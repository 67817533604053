import { useState } from "react";
import * as React from 'react';
import { Button } from "react-bootstrap";
import Map, { Marker } from 'react-map-gl';
import { Link } from "react-router-dom";
import 'mapbox-gl/dist/mapbox-gl.css';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';

const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!name || !email || !message) {
            toast.error('Please fill all the fields');
            return;
        }
        setLoader(true);

        fetch('https://1axpo03wae.execute-api.us-east-1.amazonaws.com/dev/mail-send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, message }),
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    toast.success('Message sent successfully');
                    setName('');
                    setEmail('');
                    setMessage('');
                    setLoader(false);
                } else {
                    toast.error('Something went wrong');
                    setLoader(false);
                }
            }).catch((err) => {
                toast.error('Something went wrong');
                setLoader(false);
            });


        setLoader(false);
    }
    return (
        <>
            <div id="content">
                <div className="container">
                    <div className="title1">CONTACT INFORMATION</div>
                    <div className="title2">Connect with us for more details for Hotel stay near Asbury Park, NJ</div>
                    <div className="contact-location-wrapper">
                        <div id="map">
                            <Map
                                mapboxAccessToken="pk.eyJ1IjoiZmVuaWxuMzkiLCJhIjoiY2x1enR4OXJiMG1xNTJpb2FqNXlzd3IxeSJ9.CUL7Jjew9CS19rlkFgi7nQ"
                                initialViewState={{
                                    longitude: -74.00600257742474,
                                    latitude: 40.21075028872414,
                                    zoom: 13.5
                                }}
                                mapStyle="mapbox://styles/mapbox/streets-v11"
                            >
                                <Marker longitude={-74.00600257742474} latitude={40.210390288} anchor="bottom" >
                                    <img src="./images/location.png" style={{ maxHeight: '50px' }} />
                                </Marker>
                            </Map>

                        </div>
                        <div className="contact-location">
                            <div className="txt1">KEEP IN TOUCH</div>
                            <div className="txt2">INN AT
                                OCEAN GROVE ADDRESS</div>
                            <div className="txt4">27 Webb Ave
                                <br /> Ocean Grove NJ, 07756 United States
                                <br />
                                <br />
                                <Link
                                    target="_blank"
                                    to='https://www.google.com/maps?daddr=27+Webb+Ave,+Ocean+Grove,+NJ+07756co'
                                >
                                    <Button className='btn-cf-submit' style={{ backgroundColor: '#0073B7', borderRadius: "3px" }}> GET DIRECTION </Button>
                                </Link>
                            </div>
                            <div className="txt5">
                                <dl className="dl1">
                                    <dt>Call:</dt>
                                    <dd><a href="tel:7327758847">(732) 775-8847</a></dd>
                                    <dt>Email:</dt>
                                    <dd><a style={{ textDecoration: 'none' }} href="mailto:Info@TheInnAtOceangrove.com">Info@TheInnAtOceangrove.com</a></dd>
                                    <dt>Facebook:</dt>
                                    <dd><a style={{ textDecoration: 'none' }} href="https://www.facebook.com/TheInnatOceanGrove/" target='_blank'>The Inn at Ocean Grove</a></dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="title3">CONTACT FORM</div>
                    <div id="fields">
                        <form id="ajax-contact-form" className="form-horizontal clearfix" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="inputName2">Name <span>*</span></label>
                                                <input type="text" onChange={e => setName(e.target.value)} required className="form-control" id="inputName" name="name" value={name}
                                                    placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="inputEmail">Email <span>*</span></label>
                                                <input type="email" onChange={e => setEmail(e.target.value)} required className="form-control" id="inputEmail" name="email" value={email}
                                                    placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="inputMessage2">Comments <span>*</span></label>
                                        <textarea className="form-control" onChange={e => setMessage(e.target.value)} required  id="inputMessage2" name="content" value={message}
                                            placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn-default btn-cf-submit">{!loader && ('Send Message')} {loader && (<Spinner animation="border" size="sm" />)} </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;